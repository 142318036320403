.container {
  color: #fff;
  font-size: 1.5rem;
}

.container button {
  display: block;
  background: var(--color2-light);
  color: var(--color2-dark);
  border: 5px solid var(--color1-light);
  width: 15rem;
  text-transform: uppercase;
}

.container button:hover {
  border: 5px solid var(--color1-light);
}

.navlink {
  opacity: 0;
}
.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  transform: rotate(-90deg);
  transition: opacity 1s ease-out;
  animation: moon 10s infinite;
}

@keyframes moon {
  from {
    opacity: 0;
    box-shadow: inset 0px 1px #fff, inset 0px 1px 1px 1px #fff;
  }

  to {
    opacity: 1;
    box-shadow: inset 0px 5rem #fff, inset 0px 5rem 1px 1px #fff;
  }
}
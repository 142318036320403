@import '../conf/colors.css';

.content {
  display:flex;
  height:100vh;
  align-items:center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  flex-direction: column;
}

.content > * {
  margin: 1rem;
}

.logo {
  width: 7rem;
  margin: 0 auto 2rem auto;
  transition: 1.2s ease;
}

.content .poem {
    font-family: 'Merriweather', serif;
    font-size: .8rem;
    line-height: 1.5rem;
    margin: 2rem auto;
    color: var(--color2-light);
}
@import '../conf/colors.css';

.container {
  position: absolute;
  z-index: 900;
  letter-spacing: 0.2rem;
}

.btn-back {
  position: fixed;
  top: 1.3rem;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  animation: anim-btn-back 1s;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .btn-back {
    width: 40px;
    height: 40px;
  }
}

.btn-back svg {
  fill: var(--color1-light);
}

.btn-back:focus {
  outline: none;
}

@keyframes anim-btn-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hamburger-button input
{
  z-index: 1100;
}

.hamburger-button input
{
  position: fixed;
  display: block;
  width: 3rem;
  height: 2.5rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  opacity: 0;
}

.hamburger-button input:checked ~ .wrapper
{
  transform: scale(1.0, 1.0);
  opacity: 1;
}

.hamburger-button {
  position: fixed;
  display: block;
  width: 3rem;
  top: 1rem;
  right: 1rem;
  user-select: none;
}

.hamburger-icon hr {
  border: 2px solid;
  color: var(--color1-light); 
  width: 2rem;
  margin: .5rem;
}

.hamburger-button.highlight hr {
  animation: .5s linear 1s 40 alternate pulse;
}

@keyframes pulse {
  from {
    transform: scaleX(1);
    color: var(--color1-light); 
  }
  to {
    transform: scaleX(1.2);
    color: var(--color2-light);
  }
}

.btn-close {
  composes: btn-close from '../App.css';
  top: 1rem;
  right: 1rem;
}

.nav {
  z-index: 1000;
}

.nav .wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  background-color: #000;
  color: #999;
  /*slide*/ 
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.8s ease-out;
}

@media only screen and (max-width : 800px) {
  .nav .wrapper {
    font-size: 1rem;
  }
}

.nav ul {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  list-style: none;
  padding: 0;
}

.nav li {
  height: 5rem;
}

.link {
  display: block;
  height: inherit;
  composes: link from '../App.css';
}
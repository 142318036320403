:root {
  --color1-dark: rgb(2,0,36);
  --color1-dark-a: rgb(2,0,36,.5);
  --color1-light: rgb(213, 185, 87);
  --color2-dark: rgb(0, 0, 0);
  --color2-light: rgb(250, 250, 255);
  --color-grey-light: rgb(210, 210, 230);
  --color3: rgb(191, 136, 144, .6);

  --bga1-dark: rgb(2,0,36, 0.97);
  --bga1-light: rgb(207, 181, 59, 0.97);
}
@import '../conf/colors.css';

.container {
  position: relative;
}

.icons {
  list-style: none;
  padding: 0;
  margin: 2rem 1rem;
  text-align: center;
}

.icons li {
  display: inline-block;
  padding: 0 .7rem;
}

.icon {
  display: block;
  width: 3rem;
  height: 3rem;
  fill: var(--color-grey-light);
  transition: 1s;
}

.icon:hover {
  fill: var(--color2-light);
}


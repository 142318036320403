.container {
  position: relative;
  width: 50%;
  margin: 0 auto;
  //opacity: 0;
}

.container.vertical {
  width: 400px;
}

@media (max-width: 1024px) {
  .container {
    overflow: visible; 
  }
}

@media (max-width: 800px) {
  .container {
    width: 100%;
  }
  .container.vertical {
    width: 80%;
  }
}

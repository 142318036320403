@import '../conf/colors.css';

.container {
  position: relative;
  min-height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
  transition: opacity 300ms ease-in;
  cursor: pointer;
  border-bottom: 1px dashed var(--color2-light);
}

.container:hover {
  opacity: 1;
}

.desc {
  position: absolute;
  text-align: left;
  color: white;
  bottom: 0;
  left: 5%;
  padding: 2em;
  max-width: 400px;
  min-height: 300px;
  background: rgba(0, 0, 0, .4);
}

.title {
  composes: title from '../App.css';
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 3em;
  font-weight: bold;
  color: var(--color2-light);
}

.tech-data {
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.synopsis {
  font-size: 1em;
  font-weight: bold;
}

.roles {
  list-style: none;
  padding: 0;
}

.roles li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.3em;
  border: 1px solid var(--color2-light);
}

@media (max-width: 800px) {
  .title {
    font-size: 1.8em;
  }
  .desc {
    left: 0%;
  }
}
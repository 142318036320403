.button {
	cursor: pointer;
	border-radius: 0;
	color: var(--color2-light);
	background-color: transparent;
	border: 1px solid var(--color2-light);
	width: auto;
	height: auto;
	padding: 1rem;
	margin:  2rem auto;
}

.button:hover {
	border: 1px solid var(--color1-light);
	color: var(--color1-light);
}
@import '../conf/colors.css';

.container {
  position: relative;
  display: none;
  height: auto;
  padding: 1rem;
  margin: 0; 
  background-color: #fff;
}

.container.show {
  display: block;
}

.btn-close {
  composes: btn-close from '../App.css';
  top: 1em;
  right: 1em;
}

.details {
  margin: 2rem 10rem;
}

@media only screen and (max-width : 1024px) {
  .details {
    margin: 0;
  }
}

.external-link {
  display: block;
  composes: bow from '../App.css';
  font-weight: bold;
  text-decoration: underline;
}

.col-title {
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  letter-spacing: 0.3em;
}

.description {
  column-count: 2;
  white-space: pre-line;
  line-height: 1.7rem;
  text-align: left;
  margin: 1rem 0; 
  padding: 0; 
}

.description,  p:has(.external-link) {
  font-size: 1.2rem;
}

@media only screen and (max-width : 1024px) {
  .description {
    column-count: 1;
  }
  .description,  p:has(.external-link) {
    font-size: 1rem;
  }
}

.flex {
  display: flex;
}

@media only screen and (max-width : 1024px) {
  .flex {
    flex-direction: column;
  }
}

.roles {
  flex: 1;
  padding: 0;
}

.roles ul {
  list-style: none;
  padding: 0;
}

.roles li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.3em;
  border: 1px solid var(--color1-dark);
}

.credits {
  flex: 1;
  white-space: pre-line;
}

.distinctions {
  flex: 1;
}

.distinctions ul {
  list-style: none;
  padding: 0;
}
@import '../conf/colors.css';

.content {
	display:flex;
	height:100vh;
	align-items:center;
	justify-content: center;
	flex-direction: column;
}


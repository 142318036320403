@import '../conf/colors.css';

.container {
  position: relative;
}

.logo {
  cursor: pointer;
	animation: anim-logo .3s ease-in;
}

@keyframes anim-logo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.name {
  margin: 0;
  color: var(--color1-light);
  font-size: .7rem;
  letter-spacing: 0.2em;
  padding-left: 0.1em;
  text-transform: uppercase;
  text-align: center;
  animation: anim-brand-name .3s ease-in;
}

@keyframes anim-brand-name {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
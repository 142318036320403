@import '../conf/colors.css';

.presentation {
  height: inherit;
  margin: 0;
  padding: 2rem 20%;
  background-color: var(--color2-light);
  color: var(--color2-dark);
  font-size: 1.4rem;
}

@media only screen and (max-width : 1024px) {
  .presentation {
    padding: 2rem 10%;
  };
}

@media only screen and (max-width : 800px) {
  .presentation {
    padding: 1.2rem;
    font-size: 1rem;
  };
}

.portrait {
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media only screen and (max-width : 800px) {
  .portrait {
    width: 7rem;
  }
}

.poem {
  font-size: 1.2rem;
  padding: 1rem 8rem;
  background-color: var(--color3);
  border-radius: 0.5rem;
}

.poem p {
  text-align: justify;
  margin: 0 auto;
}

@media only screen and (max-width : 800px) {
  .poem {
    padding: 1rem 2rem;
    font-size: 0.7rem;
  }
  .poem p {
    text-align: left;
  }
}

.link {
  composes: link from '../App.css';
  color: var(--color1-dark);
  font-weight: bold;
}

.link:hover {
  color: var(--color1-light);
}

@import '../conf/colors.css';

.container {
  position: relative;
  height: auto;
  padding: 3rem 10rem;
  border-bottom: 1px dashed var(--color-grey-light);
  color: var(--color-grey-light);
} 

.container.shift {
  margin-top: 12rem;
}

@media only screen and (max-width : 1024px) {
  .container {
    padding: 1rem 3rem;
  }
  .container.shift {
    margin-top: 8rem;
  }
}

.title {
  text-align: center;
  margin: 2rem;
  margin-top: 0;
}

@media only screen and (max-width : 800px) {
  .title {
    font-size: 1.2rem;
  }
}

.roles {
  margin: 2rem;
  text-align: center;
}

.roles ul {
  list-style: none;
  padding: 0;
}

.roles li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.3em;
  border: 1px solid;
}

.credits {
  white-space: pre-line;
  text-align: center;
}
@import '../conf/colors.css';

.content {
  display:flex;
  height:100vh;
  align-items:center;
  justify-content: center;
  color: var(--color2-light);
  font-size: 1.5rem;
  flex-direction: column;
  padding: 5rem 0; 
}

.details {
	text-align: center;
}
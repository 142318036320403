@import './conf/colors.css';

.App {
  height: 100%;
}

/*.App.home, .App.art {
  display:flex;
  height:100vh;
  align-items:center;
  justify-content: center;
  flex-direction: column;
}*/

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.lunographe.com/portfolio/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  src: url(https://www.lunographe.com/portfolio/assets/fonts/Merriweather/Merriweather-Light.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(49,0,74,1) 0%, rgba(2,0,36,1) 100%) fixed;
}

a, .link {
	color: var(--color1-light);
	text-decoration: none;
  transition: .5s ease-out;
}

a:hover, .link:hover {
  color: var(--color2-light);
  transition: .5s ease-out;
}

.bow {
  color: var(--color1-light);
}

.bow:hover {
  color: var(--color1-dark);
}

main {
  height: auto;
}

/* Buttons */

button:focus {
  outline: none;
}

.btn-close {
	position: absolute;
  width: 2rem;
  height: 2rem;
  opacity: 0.8;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn-close::hover {
  opacity: 1;
}
.btn-close::before, .btn-close::after {
  position: absolute;
  content: ' ';
  top: 0;
  height: inherit;
  width: 1px;
  background-color: var(--color-grey-light);
}
.btn-close::before {
  transform: rotate(45deg);
}
.btn-close::after {
  transform: rotate(-45deg);
}
.btn-close:focus {
  outline: none;
}

@import-normalize;

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
	height: 100vh;
}

#root {
  height: inherit;
}
